@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.quiz-description {
	.app-card-content {
		@include cardSecondary();
		display: flex;
		flex-direction: row;
		color: $color-white;

		@media screen and (max-width: 705px) {
			flex-direction: column;
		}
	}
}

.quiz-description-container {
	background-color: $color-bg-light;
	display: flex;
	align-items: center;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.quiz-description-container.desktop {
	background: none;
	flex-direction: row;
}

.quiz-description-container.tablet {
	background: none;
	flex-direction: column;
}

.quiz-description-container.mobile {
	background: none;
	flex-direction: column;
}

.quiz-description-container p {
	font-size: 16px;
	padding-right: 32px;
}

.quiz-content {
	.app-card-content {
		@include cardSecondary();
	}

	&__title {
		color: $color-yellow;
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 16px;
	}
}

.quiz-content-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.points-container {
	white-space: nowrap;
	margin-right: 48px;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	color: $color-yellow;
}

.points-container > * {
	margin: 5px;
	vertical-align: middle;
	display: inline-block;
}

.quiz-question {
	color: $color-white;
	font-size: 16px;
	line-height: 20px;
}

.quiz-icon-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.quiz-answers-container {
	margin-top: 40px;
}

.quiz-answers-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;

	@media screen and (max-width: 968px) {
		justify-content: space-between;
	}
}

.quiz-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	border-radius: 50px;
	color: white;
	margin: 16px 16px 16px 0;
	padding: 1rem;
	background: transparent;
	border: 2px solid $color-yellow;
	max-width: 300px;
	flex-grow: 1;
	cursor: pointer;

	@media screen and (max-width: 968px) {
		width: 48%;
		max-width: 48%;
		margin: 16px 0;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
		max-width: 100%;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		box-shadow: 0px 2px 12px 0px $card-shadow-color;
	}

	&-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: 2px solid $color-yellow;
		height: 25px;
		width: 25px;
		min-width: 25px;
		border-radius: 50%;
		&--inner {
			width: 15px;
			height: 15px;
			background-color: transparent;
			border-radius: 50%;
		}
	}

	&-answer {
		flex-grow: 1;
		text-align: left;
	}

	&-selected {
		.quiz-button-circle--inner {
			background-color: $color-yellow;
		}
	}

	&-correct {
		box-shadow: none;
		border-color: green;
		cursor: initial;

		&:hover {
			box-shadow: none;
		}

		.quiz-button-circle {
			border: 3px solid green;
			background-color: transparent;

			&--inner {
				background-color: green;
			}
		}
	}

	&-wrong {
		border-color: red;
		box-shadow: none;
		cursor: initial;

		&:hover {
			box-shadow: none;
		}

		.quiz-button-circle {
			border: 3px solid red;
			background-color: transparent;

			&--inner {
				background-color: red;
			}
		}
	}
}

.quiz-button.desktop {
	background: none;
	width: 23%;
}

.quiz-button.tablet {
	background: none;
	width: 48%;
}

.quiz-button.mobile {
	background: none;
	width: 98%;
}

.quiz-button-border.selected {
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	border-radius: 50px;
	z-index: -1;
}

.quiz-button-description {
	display: inline-block;
	padding: 10px 75px 10px 25px;
}

.quiz-submission {
	&-container {
		margin: 16px 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		justify-items: center;

		&.desktop {
			background: none;
			justify-content: flex-end;
		}

		&.tablet {
			justify-items: center;
			background: none;
			flex-direction: column;
		}

		&.mobile {
			background: none;
			flex-direction: column;
		}
	}

	&-content {
		color: $color-gray;
		display: flex;
		flex-direction: column;
		align-items: center;
		.app-button-generic-container {
			margin-bottom: 8px;
			width: 95px;
		}
	}
}

.correct-explanation {
	font-size: bold;
	color: white;
}

.wrong-explanation {
	font-size: bold;
	color: white;
	margin-bottom: 16px;
}
