@import "src/assets/sass/variables.scss";

.faq-tile-container {
	justify-content: space-between;
	box-sizing: border-box;
	backdrop-filter: blur(40px);
	border-radius: 20px;
	cursor: pointer;
}

.faq-tile-title {
	color: $color-yellow;
	flex: 1;
	font-size: 18px;
}

.faq-header>p {
	font-size: 18px;
}

.faq-tile-title-container {
	align-items: center;
	display: flex;
	font-family: 'Slalom Sans';
	justify-content: space-between;
	font-family: Slalom Sans;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	border-radius: 16px;
}

.faq-tile-body-container {
	color: $color-white;
	font-family: SlalomSans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	margin-top: 32px;
	white-space: pre-wrap;
}

.faq-tile-arrow-icon {
	margin-left: 5px;
}