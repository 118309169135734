@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.app-card {
	width: 100%;
	@include fontStandard();
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
}

.app-card-title {
	font-size: 32px;
	text-transform: uppercase;
}

.app-card-content {
	@include card();
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-grow: 1;
	width: 100%;
	padding: 32px;
	border-radius: 16px;
	border: 1px solid $transparent-light-purple;
}

.app-card-header {
	@include sectionHeader();
}

@media screen and (max-width: 1182px) {
	.app-card-title {
		font-size: 18px;
	}
}
