.prelaunch-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: SlalomSans;
	color: white;
}

.prelaunch-container-desktop>div {
	text-align: center;
}

.prelaunch-video-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #cccccc;
	z-index: -1;
}

.prelaunch-background-video {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.prelaunch-background-image {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	height: calc(100vh + 24px);
	position: fixed;
	top: -12px;
	left: 0;
}

.prelaunch-topic-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 auto;
	font-family: SlalomSansBold;
	text-align: center;
	height: 100vh;
}

.prelaunch-content-desktop {
	width: 1080px;
}

.prelaunch-content-tablet {
	width: 700px;
}

.prelaunch-content-mobile {
	width: 320px;
}

.prelaunch-content-desktop h2 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 16px;
}

.prelaunch-content-tablet h2 {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 16px;
}

.prelaunch-content-mobile h2 {
	font-size: 12px;
	line-height: 18px;
	margin: 8px 0;
}

.prelaunch-content-desktop p,
.prelaunch-content-tablet p,
.prelaunch-content-mobile p {
	display: inline-block;
	margin: 0;
}

.prelaunch-topic-name-desktop {
	font-size: 32px;
	line-height: 38px;
}

.prelaunch-topic-name-tablet {
	font-size: 18px;
	line-height: 22px;
}

.prelaunch-topic-name-mobile {
	font-size: 12px;
	line-height: 14px;
}

.prelaunch-topics {
	line-height: 12px;
}

.prelaunch-festival-title-desktop {
	width: 650px;
	margin: 0 auto;
	padding: 64px 0 24px 0;
}

.prelaunch-festival-title-tablet {
	width: 500px;
	margin: 0 auto;
	padding: 64px 0 24px 0;
}

.prelaunch-festival-title-mobile {
	width: 260px;
	margin: 0 auto;
	padding: 48px 0 16px 0;
}

.app-button-generic-container {
	margin: 0;
}

@media screen and (max-width: 705px) {
	.app-button-generic-container {
		margin: 0;
	}
}

.button-container-desktop {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
	gap: 20px;
}

.button-container-tablet {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
	gap: 20px;
}

.button-container-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 24px 0;
	margin-bottom: 60px;
	gap: 20px;
}

.pride-description {
	width: 580px;
	font-size: 22px;
	font-family: 'Slalom Sans';
	line-height: 26px;
	text-align: center;
	font-weight: 400;
	margin: 0 auto 48px auto;
}

.pride-description.mobile {
	width: 250px;
	margin: 0 0 24px 0;
	text-align: left;
}

@media (min-width: 576px) {
	.button-mt {
		margin-top: calc(100vh - 50em);
	}
}