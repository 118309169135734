@import "src/assets/sass/variables.scss";

.FAQ-container-desktop {
	margin-top: 450px;
}

.FAQ-container-tablet,
.FAQ-container-mobile {
	margin-top: 200px;
}

.faq-link:hover {
	color: black;
}

.faq-title {
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 700;
	display: flex;
	align-items: center;
	flex-direction: row;
	color: $color-slalom-primary;
	margin: 64px 0px;

}

.faq-header {
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 700;
	color: $color-slalom-primary;
	padding: 16px 0px;
	margin: 64px 0px 16px 0px;
	text-transform: uppercase;
}

.faq-header-large {
	font-size: 18px;
	line-height: 22px;
}

.faq-header-mobile {
	font-size: 18px;
	line-height: 22px;
	margin: 32px 0px 16px 0px;
}