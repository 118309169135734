@import "src/assets/sass/variables.scss";
@import 'src/assets/sass/mixins';

input::placeholder {
	font-family: 'Slalom Sans';
	font-weight: 400;
	font-size: 24px;
	color: #FFFFFF;
	opacity: 1;
	letter-spacing: 0;
}

.claim-points-bar {
	@include cardSecondary();
	display: flex;
	width: 100%;
	padding: 24px;
	align-items: center;
	background: $card-bg;
	backdrop-filter: blur(40px);
	border-radius: 16px;
	border: 2px solid $color-yellow;
	font-family: SlalomSans;
	color: $color-white;
}

.claim-points-bar-desktop {
	flex-direction: row;
	justify-content: space-between;
	align-items: start;
	padding-bottom: 0;
}

.claim-points-bar-tablet {
	flex-direction: column;
}

.claim-points-bar-mobile {
	flex-flow: row wrap;
}

.claim-points-description-container {
	display: flex;
	flex-direction: column;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
}


.claim-points-header {
	font-weight: 700;
	font-size: 16px;
	//margin-bottom: 50px;
	height: 20px;
	color: $color-yellow;
}

.claim-points-description-container p {
	margin-top: 16px;
	padding-top: 16px;
	padding-bottom: 24px;
	line-height: 18px;
}

.description-container-desktop {
	min-width: 300px;
	max-width: 350px;
}

.description-container-tablet,
.description-container-mobile {
	width: 100%;
}

.claim-points-form-inline {
	display: flex;
	align-items: center;
}

.claim-points-form-inline-desktop {
	flex-direction: row;
	margin-left: 48px;
	width: 100%;
}

.claim-points-form-inline-tablet,
.claim-points-form-inline-mobile {
	flex-flow: row wrap;
	width: 100%;
	margin-top: 32px;
}

.claim-points-enter-code-container {
	display: flex;
	flex-direction: column;
	//margin-bottom: 32px;
	color: black;
	font-size: 16px;
	width: 100%;
}

.claim-points-input {
	box-sizing: border-box;
	display: flex;
	width: 100%;
	height: 48px;
	font-size: 20px;
	padding-left: 25px;
	//margin-bottom: 15px ;
	text-align: left;
	letter-spacing: 32px;
	background: $transparent-light-purple;
	border: 1px solid $color-bg-border;
	backdrop-filter: blur(40px);
	color: white;
	border-radius: 50px;
}

.claim-points-input:focus {
	outline: none;
}

.claim-points-input-error {
	background: #FFCCC0;
	border-color: transparent;
	color: $color-black;
}

.claim-points-input-success {
	background: #C3E4BE;
	border-color: transparent;
	color: $color-black;
}

#claim-points-input-message {
	height: 24px;
	padding: 8px 0;
	color: #FFFFFF
}

.claim-points-button-container {
	display: flex;
	flex-direction: column;
	color: black;
	width: 100px;
	height: 50px;
	margin-left: 16px;
	margin-top: 36px;
}

#claim-points-button-incomplete {
	background-color: #d1d1d1;
	border: 0px;
}

#claim-points-button-complete {
	background-color: $color-slalom-primary;
	border: 2px solid #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 0px 10px 1px #008bff;
	border-radius: 8px;
}

@media screen and (max-width: 1182px) {
	.claim-points-button-container {
		width: 100%;
		margin: 0;
	}
}