@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

/* Dropdown Styles */
.dropdown-label {
	color: white;
}

.dropdown-container {
	width: unset !important;
	display: flex;
}

.ui.multiple.dropdown {
	position: relative;
	border-radius: 1px;
	width: 175px;
	height: 75px;
	background-color: $color-slalom-primary;
	border: 1px solid rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(40px);
	border-radius: 50px;
	z-index: -1;
	overflow: visible !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
	font-family: Slalom Sans;
	font-style: normal;
	font-weight: 900;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;
	z-index: 10;
}

.ui.dropdown .menu > .item:hover {
	background-color: hsla(0, 100%, 100%, 0.3);
	color: #ffffff;
}

.ui.dropdown .menu > .item:hover.active {
	background-color: unset;
}

.ui.dropdown .menu > .item {
	height: 50px;
	border-top: unset !important;
	color: #ffffff;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
	background: #ffffff;
	opacity: 0.1;
}

.ui.selection.dropdown,
.ui.selection.active.dropdown,
.ui.active.visible.selection.dropdown {
	background-color: $transparent-light-purple;
}

.ui.selection.active.dropdown .menu {
	background-color: $color-dark-purple;
	box-sizing: border-box;
	border-radius: 0px 0px 20px 20px;
	border-color: transparent;
	opacity: 1;
	min-height: auto;
	max-height: 350px;
}

.ui.dropdown > .text,
.ui.selection.visible.dropdown > .text:not(.default),
.ui.selection.active.dropdown,
.ui.selection.dropdown:focus,
.ui.selection.dropdown:hover,
.ui.selection.active.dropdown:hover,
.ui.dropdown.active.label:hover,
.ui.selection.active.dropdown:hover .menu {
	border-color: $color-light-purple;
}

.ui.dropdown > .text {
	color: $color-white;
}

.ui.selection.dropdown .menu > .item {
	font-size: 16px;
	padding: 1rem 1rem !important;
}

.ui.selection.dropdown .menu > .item::before {
	content: '';
	position: absolute;
	height: 1px;
	bottom: 0;
	background-color: #d1d1d1;
	width: 90%;
}

.ui.selection.dropdown {
	width: 100%;
	height: 100%;
	background: $transparent-light-purple;
	border: 1px solid $color-light-purple;
	backdrop-filter: blur(40px);
	border-radius: 50px;
	z-index: 0;
}

.ui.selection.dropdown:focus {
	backdrop-filter: blur(40px);
	border-radius: 25px;
}

.ui.dropdown > .dropdown.icon:before {
	content: '';
	pointer-events: all;
}

.ui.active.selection.dropdown > .dropdown.icon,
.ui.visible.selection.dropdown > .dropdown.icon {
	background-image: url('../../assets/images/up-arrow.svg');
}

.ui.dropdown > .dropdown.icon {
	background-image: url('../../assets/images/down-arrow.svg');
	background-size: auto;
	background-repeat: no-repeat;
	top: 2.2rem !important;
	right: 1.3rem !important;
}

.ui.multiple.dropdown > .label {
	margin: 3px 0px 3px 5px;
	height: 40px;
	padding: 10px;
	text-align: left;
	border-radius: 50px;
	background: $color-dark-purple;
	border: none;
	font-size: 16px;
	line-height: 19px;
	color: $color-white;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
	background-color: unset;
}

i.icon.delete:before {
	content: '';
	pointer-events: all;
}

.ui.label > .close.icon,
.ui.label > .delete.icon {
	background-image: url('../../assets/images/tooltip_close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 11px;
	height: 11px;
	margin-right: 0;
	margin-left: 1rem;
	font-size: unset;
	opacity: unset;
}
