@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.app-viewrecording-primary {
    border-radius: 50px;
	width: 100%;
	height: 55px;
	background-color: $transparent-light-purple;
	border: 2px solid $color-yellow;
	backdrop-filter: blur(40px);
	border-radius: 50px;
    display: flex;

	font-family: "Slalom Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	justify-content: center;
	line-height: 19px;
	padding: 16px;
	color: $color-yellow;
}

.app-viewrecording-primary:hover {
    background-color: #5e6072;
    color: $color-yellow;
}

.app-viewrecording-container {
    margin: 0px 12px;
}