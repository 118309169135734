@import "src/assets/sass/variables.scss";

.section-container {
  width: 100%;
  color: $color-black;
}

.section-title-container {
  display: flex;
  margin: auto;
  height: 2.4em;
  justify-content: space-between;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: black;
}

.other-ways-to-earn-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;

  .app-card {
    width: 48%;

    h2 {
      height: 60px;
    }
  }
}

.other-ways-to-earn-tile {
  background: radial-gradient(
    100% 16109.47% at 0% 0%,
    rgba(255, 255, 255, 0.42) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  backdrop-filter: blur(40px);
  border-radius: 16px;
  padding: 16px;
  margin: 0 0 32px 0;
  width: 32%;
  height: 400px;
}

.other-ways-to-earn-tile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 100%;
  justify-content: space-between;
}

.tile-title {
  padding: 16px;
  font-family: Slalom Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: $color-yellow;
}

.points-icon {
  margin: 7px;
  width: 40px;
  height: 40px;
}

.points-value {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: $color-yellow;
}

.tile-points {
  display: flex;
}

.tile-description {
  padding: 16px;
  font-family: "Slalom Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $color-white;
  height: 150px;
}

.tile-button-container {
  margin: 32px auto 32px auto;
  padding: 0 16px;
}

@media screen and (max-width: 960px) {
  .other-ways-to-earn-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    flex-wrap: nowrap;
    height: 100%;

    .app-card {
      min-width: 350px;
      max-width: 350px;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  .tile-title {
    padding: 16px;
    font-family: "Slalom Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  .tile-button {
    width: 100%;
    margin: 16px 16px 16px 0;
    padding: 0 16px 0 16px;
  }
  .tile-description {
    height: 200px;
  }
}

@media screen and (max-width: 705px) {
  .other-ways-to-earn-container {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 100%;
    flex-wrap: nowrap;
    height: 100%;
  }
}
