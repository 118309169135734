.about-list-desktop-component {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	column-gap: 16px;
	row-gap: 16px;
	padding-bottom: 24px;
}

.about-list-tablet-component,
.about-list-mobile-component {
	display: flex;
	flex-direction: row;
	overflow: auto;
	flex-wrap: nowrap;
	text-size-adjust: 22px;
	margin-bottom: 11px;
}