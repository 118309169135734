@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins.scss";

.navigation-bar-container {
  @include headerFooter();
  width: 100vw;
  height: 80px;
  z-index: 100;
  display: flex;
  position: fixed;
  transform-style: preserve-3d;
}

.nav-container {
  display: flex;
}

.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-link {
  color: rgba(255, 255, 255, 1);
  align-self: center;
  position: relative;
  height: 50%;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: normal;
  width: 80px;
  padding: 1.5em;
}

.nav-link-open {
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  align-self: center;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1rem;
}

.nav-logo {
  margin-left: 15px;
  align-self: center;
  z-index: 10;
}

.nav-logo>img {
  height: 19.5px;
}

.nav-user-container {
  display: flex;
  float: right;
  color: #1be1f2;
  z-index: 10;
  margin-left: 1.6rem;
}

@media screen and (max-width: 705px) {
  .nav-user-container {
    margin-left: 0;
  }
}

.nav-points {
  font-size: 16px;
  align-self: center;
  cursor: default;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  display: block;
  margin-right: 8px;
}

@media screen and (max-width: 705px) {
  .nav-points {
    display: none;
  }
}

.nav-user {
  margin: 15px;
  height: 50px;
  width: 50px;
  align-self: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-user-container {
  margin-right: 16px;
}

.nav-user-img {
  border-radius: 50%;
}

/* HOVER STATE */
.nav-link-text:before {
  content: '';
  position: absolute;
  visibility: hidden;
  height: 2px;
  width: 0;
  right: 50%;
  bottom: 7px;
  background-color: currentColor;
  transition: all 0.2s ease-in-out;
}

.nav-link:hover {
  background: $color-slalom-primary;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a:hover {
  text-decoration: unset;
  color: unset;
}

.nav-link.active>.nav-link-text {
  position: relative;
}

.nav-link.active>.nav-link-text:before {
  right: 0;
  bottom: -5px;
  width: 100%;
  visibility: visible;
  color: $color-slalom-primary
}

.nav-link.active {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  text-decoration-color: $color-slalom-primary;
}

.nav-link:hover.active {
  color: $color-slalom-primary;
  font-weight: bold;
  background: unset;
  -webkit-text-fill-color: $color-slalom-primary;
}

/* OPEN NAV */
.nav-link-open:before {
  content: '';
  position: absolute;
  height: 1px;
  bottom: 0;
  background-color: #fff;
  width: 90%;
}

.nav-link-open:hover:before {
  visibility: visible;
}

.nav-link-open.active {
  color: #fff;
  font-weight: bold;
  height: 50px;
}

.nav-link-open:hover.active {
  background-color: unset;
}

.nav-link-open:hover {
  background-color: #fff0c9;
}

/* Hamburger Nav */

.nav-hamburger {
  position: relative;
}

.nav-hamburger span {
  z-index: 10;
  cursor: pointer;
  align-self: center;
  right: 200px;
  color: #ffffff;
}

.nav-menu {
  list-style: none;
  position: fixed;
  top: -280px;
  background: #151D2E;
  right: 0;
  width: 100%;
  overflow: hidden;
  max-width: 100vw;
  z-index: 9;
  margin: 0;
  padding: 0;
  height: 258px;
  transition: all 0.2s ease-in-out;
  transform: translateZ(-10px);
}

.nav-menu.nav-show {
  top: 80px;
  width: 100%;
}

.nav-show-mobile {
  width: 100%;
  top: 80px;
}

/* Hamburger Menu Icon */

#nav-icon {
  width: 40px;
  height: 30px;
  position: relative;
  margin: 20px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 10;
  margin-right: 1rem;
}

@media screen and (max-width: 705px) {
  #nav-icon {
    margin-right: 0;
  }
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 6px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 30px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 4px;
  left: 5px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 32px;
  left: 5px;
}

.mobile-nav-rankings {
  display: flex;
  height: 50px;
  padding: 1rem 1rem;
  color: #fff;
  font-weight: 700;
  justify-content: space-between;
  width: 93%;
}