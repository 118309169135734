@import 'src/assets/sass/variables.scss';

.tooltip-container {
	position: relative;
	display: flex;
	height: auto;
	align-items: center;
	margin-top: 24px;
}

.tooltip-icon {
	cursor: pointer;
	border: 1px solid white;
	border-radius: 20px;
	background: #624d83;
}

.tooltip-content {
	position: absolute;
	width: 226px;
	right: 40px;
	top: 0px;
	z-index: 10;
	padding: 16px;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000;
	background: #c7b9ff;
	border-radius: 20px;
}

.tooltip-close {
	text-align: right;
	cursor: pointer;
}
