@import './variables';

@mixin cardSecondary {
	@include card();
	border: 2px solid $color-yellow;
	box-shadow: $card-shadow;
}

@mixin fontStandard {
	color: $color-yellow;
	font-family: Slalom Sans;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	line-height: 20px;
}

@mixin card {
	background: $card-bg;
	backdrop-filter: blur(21px);

	p {
		@include fontStandard();
		color: $color-white;
	}
}

@mixin leaderboardCard {
	background: $transparent-light-purple;
	border: 1px solid $color-light-purple;
	color: $color-yellow;
}

@mixin fontHeader {
	@include fontStandard();
	font-size: 32px;
	font-weight: 700;
	line-height: 38px;
}

@mixin fontHeaderUpperCase {
	@include fontHeader();
	text-transform: uppercase;
}

@mixin headerFooter {
	background: #151d2e;
	backdrop-filter: blur(20px);
}

@mixin sectionHeader {
	@include fontHeaderUpperCase();
	margin: 32px 0px;
	display: flex;
	align-items: center;
}
