.page-banner {
  width: 100%;
  position: relative;
}

.page-banner-img {
  width: 100%;
  object-fit: cover;
}

.page-banner-img-tablet {
  height: 500px;
  object-fit: cover;
  max-width: 100%;
}

.page-banner-img-mobile {
  height: 325px;
  object-fit: cover;
  max-width: 100%;
}

.page-banner-title {
  position: absolute;
  left: 0;
}

.page-banner-title-desktop {
  bottom: 48px;
  width: 450px;
  left: 60px;
}

.page-banner-title-tablet {
  bottom: 48px;
  width: 300px;
  left: 40px;
}

.page-banner-title-mobile {
  bottom: 32px;
  left: 17.5%;
  width: 65%;
}