@import 'src/assets/sass/variables.scss';

.app-button-primary {
	align-items: center;
	justify-content: center;
	background: none;
	border: 3px solid $color-slalom-primary;
	border-radius: 50px;
	color: $color-slalom-primary;
	display: flex;
	padding: 10px;
}

.app-button-primary:hover {
	background-color: $color-app-button-primary-hover;
	color: $color-slalom-primary;
}

.app-button:hover {
	cursor: pointer;
	background-color: #5e6072;
}

.app-button-about-contact {
	margin-top: 24px;
}
