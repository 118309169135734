.error-page-desktop {
	background-image: url("../../assets/images/pages/error_404_desktop.png");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 800px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

@media screen and (max-width: 1182px) {
	.error-page {
		background-image: url("../../assets/images/pages/error_404_desktop.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: right;
	}
}

.error-page-tablet {
	background-image: url("../../assets/images/pages/error_404_tablet.png");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.error-page-mobile {
	background-image: url("../../assets/images/pages/error_404_mobile.png");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.error-btn-container-desktop {
	margin: 0 0px 100px 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: row;
	position: fixed;
	bottom: 5px;
	left: 60px;
	gap: 20px;
}

.error-btn-container-tablet {
	margin: 0 10px 85px 0;
	display: flex;
	justify-content: center;
	gap: 20px;
}

.error-btn-container-mobile {
	margin: 0 0 60px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-position: 100%;
	gap: 20px;
}

.error-btn-text-only {
	background: none;
	border: none;
	color: #deff4d;
	box-shadow: none;
	filter: none;

}

.app-button-secondary{
	color: #deff4d;
	border-radius: 50px;
}

.app-button-secondary:visited {
	background: none;
	box-shadow: none;
}

.error-btn-text-only:hover {
	color: #DEFF4D;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	background: rgba(255, 255, 255, 0.15);
	border-radius: 50px; 
	box-shadow: none;
	filter: none;
}

.error-btn-text-only:active {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	
	background: rgba(255, 255, 255, 0.3);
	border-radius: 50px; 
	box-shadow: none;
	filter: none;
	color: #deff4d;

}

.btn-label {

	/* Body 1 */

	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 14px 20px;
	color: #deff4d;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;

}
.btn-label-primary{
	/* Return home button */

	font-family: "Slalom Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #DEFF4D;
	border: 2.5px solid #DEFF4D;
	border-radius: 50px;
	padding: 14px 20px;
	gap: 10px;
	text-align: center;

}

.btn-label-primary:hover{
	
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 10px;

	background: rgba(255, 255, 255, 0.15);
	/* Privacy Week/Privacy Week Primary

	Buttons, etc.
	*/
	border: 2.5px solid #DEFF4D;
	border-radius: 50px;
}

.btn-label-primary:active{
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 10px;

	background: rgba(255, 255, 255, 0.3);

	border: 2.5px solid #DEFF4D;
	border-radius: 50px;

}


.error-page-container-large {
	margin: 0 60px;
	display: flex;
	flex-direction: column;
}

.error-page-container-mobile {
	margin: 0 40px;
	display: flex;
	flex-direction: column;
}
