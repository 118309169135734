.securyeti-container {
	background: rgba(255, 255, 255, 0.75);
	width: 300px;
	border-radius: 16px;
	padding: 24px;
	box-shadow: 0px 47px 80px rgba(0, 0, 0, 0.05),
		0px 19.64px 33.4px rgba(0, 0, 0, 0.0359),
		0px 10.5px 17.87px rgba(0, 0, 0, 0.0298),
		0px 5.89px 10.02px rgba(0, 0, 0, 0.025),
		0px 3.13px 5.32px rgba(0, 0, 0, 0.0202);
	display: none;
}

.securyeti-container img {
	width: 250px;
	height: auto;
}
