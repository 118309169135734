@import "src/assets/sass/variables.scss";

.rankings-container {
	display: flex;
	float: right;
	color: $color-black;
	font-weight: 700;
	z-index: 10;
	font-size: 16px;
	align-items: center;
	margin-left: 0.7rem;
}

.local-ranking {
	color: #FFFFFF;
}

.global-ranking {
	color: #FFFFFF;
	margin-left: 1.6rem;
}