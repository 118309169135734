@import 'src/assets/sass/variables.scss';

.events-tooltip {
	justify-content: space-between;
	display: flex;
}

.events-container {
	max-width: 1320px;
	margin: auto;
}

.filter-container {
	color: $color-yellow;
	margin: 0px 0px 0px 0px;
	position: relative;
	z-index: 1;
}

.filter-container div {
	width: 100%;
}

.filter-container div > p {
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #deff4d;
	margin-bottom: 1rem;
}

.filter-label-tablet {
	display: none;
}

.filter-label-desktop {
	display: block;
}

.filter-block-desktop {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}

.filter-block-tablet,
.filter-block-mobile {
	margin-left: unset;
	margin-right: unset;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.filter-container-desktop {
	display: flex;
	justify-content: space-around;
	padding: 32px;
	margin-bottom: 28px;
	background: $card-bg;
	border-style: solid;
	border-radius: 20px;
	border-width: 2px;
	border-color: $transparent-light-purple;
}

.filter-container-tablet,
.filter-container-mobile {
	background: $card-bg;
	padding: 20px;
	border-style: solid;
	border-radius: 20px;
	border-width: 2px;
	border-color: $transparent-light-purple;
}

.events-filter {
	margin-right: 20px;
	position: relative;
	z-index: -2;
}

.ui.dropdown:not(.button) > .default.text {
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: white;
}

.ui.multiple.dropdown > .text {
	font-weight: 700;
	position: static;
	padding: 1rem;
	max-width: 100%;
	margin: unset;
	line-height: unset;
}

.dropdown-label {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 15px;
}

.message-grid {
	display: inline-flex;
	flex-direction: row;
	align-items: stretch;
}

.message-grid-tablet {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
}

.message-grid-mobile {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	overflow-x: scroll;
	position: absolute;
}

.message-box {
	display: inline-block;
	flex: 100%;
	margin: 0px 32px 0px 0px;
	padding: 16px;
	border-radius: 20px;
	background: $card-bg;
}

.message-box-last {
	margin-right: 0px;
}

.message-box-tablet {
	margin: 0px 0px 24px 0px;
}

.message-box-mobile {
	width: 350px;
	min-width: 300px;
	margin: 0px 16px 24px 0px;
	padding: 8px;
}

.modals.dimmer .ui.scrolling.modal .visible.transition {
	margin: 1rem auto;
	display: flex;
	justify-content: center;
}

.message-box-title {
	margin: 32px 32px 32px 32px;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: $color-black;
}

.message-box-title-mobile {
	font-size: 18px;
}

.message-box-content {
	margin: 16px 32px 32px 32px;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 375;
	font-size: 16px;
	line-height: 20px;
	color: $color-yellow;
}

.event-card-grid {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	overflow-x: auto;
	margin-bottom: 48px;
	position: relative;
	z-index: 0;
}

.view-recordings-btn {
	width: 100%;
	min-height: 2em;
}

.claim-points-spacer {
	margin-bottom: 48px;
}

.event-about-container {
	display: flex;
	width: 100%;
}

@media screen and (min-width: 1183px) {
	.event-card-grid {
		flex-wrap: wrap;
		overflow: initial;
		margin-bottom: 48px;
	}
}
