.page-wrapper-prelaunch {
  display: flex;
  flex-direction: column;
}

.page-wrapper-fullsite {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
}

.page-wrapper-content {
  top: -5px;
  position: relative;
  padding: 30px 60px;
  background-position: center;
}

.page-bg-container-prelaunch {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -100;
  margin-bottom: -160px;
}

.page-bg-container-fullsite {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -100;
  margin-bottom: -80px;
}

.desktop {
  background-image: url("../../assets/images/bg-desktop.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.tablet {
  background-image: url("../../assets/images/bg-tablet.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.mobile {
  background-image: url("../../assets/images/bg-mobile.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1182px) {
  .page-wrapper-content {
    padding: 0 30px;
  }

  .page-bg-container {
    margin-bottom: -142px;
  }
}

@media screen and (max-width: 705px) {
  .page-wrapper-content {
    padding: 0 20px;
  }

  .page-bg-container {
    margin-bottom: -209px;
  }
}
