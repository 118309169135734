@import "src/assets/sass/variables.scss";
@import 'src/assets/sass/mixins';

@font-face {
	font-family: SlalomSans;
	src: url("../../assets/fonts/SlalomSans/SlalomSans_Regular.otf");
}

.team-profile-tile-container {
	flex-basis: 23.5%;
	min-width: 250px;
	max-width: 250px;
	height: 323px;
	justify-content: center;
	display: flex;
	cursor: pointer;
	font-family: SlalomSans;
	border-radius: 20px;
}

.team-profile-front-tile-image-container {
	color: FFFFFF;
	width: 100%;
	height: 241px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid $transparent-light-purple;
	border-width: 1px 1px 0 1px; 
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.team-profile-front-tile-info-container {
	width: 100%;
	padding: 0 8px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
	backdrop-filter: blur(21px);
	border: 1px solid $transparent-light-purple;
	border-width: 0 1px 1px 1px; 
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.team-profile-name-container {
	font-family: "Slalom Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: $color-slalom-primary;
}

.team-profile-role-container {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400, regular;
	color: #FFFFFF;
}

.about-info-container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.team-profile-back-tile-container {
	width: 250px;
	height: 80px;
	font-weight: 400, regular;
	font-size: 16px;
	line-height: 19px;
	margin-top: 140px;
	position: absolute;
	text-align: center;
	justify-content: center;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.team-profile-quote-container {
	margin-top: 20px;
	margin-left: 5px;
}

@media screen and (max-width: 1182px) {
	.profile-tile-margin {
		margin-right: 16px;
	}
}