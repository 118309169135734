@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.top-leader-card {
	font-family: 'Slalom Sans';
	display: flex;
	position: relative;
	border-radius: 20px;
	@include leaderboardCard();
}

.top-leader-card-bg {
	border-radius: 20px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #8b1463;
	opacity: 0.7;
	overflow: hidden;
}

.top-leader-content {
	border-radius: 20px 10px 10px 20px;
	padding: 10px 0;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 32px;
	min-height: 175px;
}

.position-image {
	position: absolute;
	background-repeat: no-repeat;
	right: -30%;
	width: 100%;
	height: 100%;
}

.top-leader-avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.top-leader-stat-h2 {
	margin: 0;
	font-size: 24px;
	font-weight: 700;
}

.top-leader-stat-h3 {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	color: $color-white;
}

.position-0 {
	background-image: url('../../assets/images/leaderboard-gold.png');
}

.position-1 {
	background-image: url('../../assets/images/leaderboard-silver.png');
}

.position-2 {
	background-image: url('../../assets/images/leaderboard-bronze.png');
}

@media screen and (min-width: 1388px) {
	.top-leader-card {
		height: 260px;
		min-width: 350px;
		max-width: 400px;
	}

	.top-leader-content {
		width: 75%;
	}
}

@media screen and (min-width: 981px) {
	.top-leader-card {
		width: 32%;
	}

	.position-image {
		right: -30%;
	}
}

@media screen and (max-width: 980px) {
	.top-leader-card {
		flex-direction: column;
		width: 31%;
		max-width: 225px;
		min-height: 301px;
		border-radius: 16px;
		overflow: hidden;
		justify-items: center;
	}

	.top-leader-content {
		border-radius: 16px 16px 8px 8px;
		align-items: center;
		padding-top: 40px;
	}

	.top-leader-rank {
		width: 40px;
		height: 40px;
		right: 10px;
		top: 10px;
	}

	.top-leader-stat-h2 {
		font-size: 18px;
	}

	.position-image {
		position: relative;
		background-repeat: no-repeat;
		background-position: center;

		right: 0;
		top: 0;
		height: 45%;
		width: 100%;
	}

	.position-0 {
		background-image: url('../../assets/images/leaderboard-gold-sm.png');
	}

	.position-1 {
		background-image: url('../../assets/images/leaderboard-silver-sm.png');
	}

	.position-2 {
		background-image: url('../../assets/images/leaderboard-bronze-sm.png');
	}
}

@media screen and (max-width: 705px) {
	.top-leader-card {
		max-width: 300px;
		min-width: 200px;
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
}
